/**
 * Much faster replacement for lodash's _.get function
 * @type {(object: any, path: string) => undefined|any}
 */
export default function getPropertyByPath(object, path) {
    if (!object || !path) {
        return undefined
    }

    // noinspection JSUnresolvedVariable
    return String(path).split(".").reduce((object, path) => {
        try {
            object = object[path]
        } catch (e) {
            return ""
        }
        return object
    }, object)
}
